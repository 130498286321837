import React from "react";
import ReactDOM from 'react-dom/client';

import AOS from "aos";
import "aos/dist/aos.css";



import App from "./App";

AOS.init({once: true});


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);
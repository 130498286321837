import React, { Component,lazy, Suspense } from "react";
import axios from "axios";
import config from "../config";
import '../css/contact.css'
import ReactCaptcha from "modern-react-captcha";

import reloadIcon from "../img/reload.jpg";

const LazyGoogleMap = lazy(() => import('./GoogleMap'));

export default class CustomComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fname: "",
      femail: "",
      fsubject: "",
      fmessage: "",
      rightcapcha: false,
    };

    // биндим сюдым методы, чтобы они, сцуко видели  this.setState
    this.handleSuccess = this.handleSuccess.bind(this);
    this.handleFailure = this.handleFailure.bind(this);
  }



  handleSuccess() {
    this.setState({ rightcapcha: true });
    document.querySelector(".modern-react-captcha").classList.add("hide");
    document.querySelector(".error-message").innerHTML = '';
    document.querySelector(".error-message").style.display = 'none';
  }

  handleFailure() {
    //this.setState({rightcapcha: false});
    //return false;
  }

  handleFormSubmit(event) {
    event.preventDefault();
    document.querySelector(".error-message").innerHTML = '';
    document.querySelector(".error-message").style.display = 'none';

    if (this.state.rightcapcha === false) {
      document.querySelector(".error-message").style.display = 'block';
      document.querySelector(".error-message").innerHTML = 'Неправильно введен проверочный код';
    } else {
      axios({
        method: "post",
        url: config.ROOT_URL + "/tools/rest/feedback",
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        data: this.state,
      })
        .then((result) => {
          this.setState({
            mailSent: result.data.data.sent,
          });

          console.log(result);
          console.log(result.data.data.sent);

          if(result.data.data.sent === 'OK')
          {
            document.querySelector(".sent-message").style.display = 'block';
            document.querySelector("#fmessage").value = '';
            document.querySelector("#fname").value = '';
            document.querySelector("#femail").value = '';
            document.querySelector("#fsubject").value = '';
            document.querySelector('.send_button').classList.add('hide');
          }
          else
          {
            document.querySelector(".error-message").style.display = 'block';
            document.querySelector(".error-message").innerHTML = result.data.data.sent;
          }
        })
        .catch((error) => this.setState({ error: error.message }));


    }
  }

  render() {
    return (
      <div>
        <section id="contact" className="contact  section-bg">
          <div className="container">
            <div className="section-title">
              <h2>Контакты</h2>
              <p>
              </p>
            </div>

            <div className="row" data-aos="fade-in">
              <div className="col-lg-5 d-flex align-items-stretch">
                <div className="info">
                  <div className="address">
                  <i className="icomoon icon-telegram"></i>
                    <p className="h4">Телеграм:</p>
                    <p><a target="_blank" rel="noreferrer" href="https://t.me/andrew_k_ru" className="telegram" aria-label="Телеграм">@andrew_k_ru</a></p>
                  </div>

                  <div className="phone">
                  <i className="icomoon icon-skype"></i>
                    <p className="h4">Skype:</p>
                    <p><a target="_blank" rel="noreferrer" href="skype:andrew-k-ru?call" className="google-plus" aria-label="Скайп">andrew-k-ru</a></p>
                  </div>


                  <div className="email">
                  <i className="icomoon icon-gmail"></i>
                    <p className="h4">Почта:</p>
                    <p><a target="_blank" rel="noreferrer" href="mailto:molbiol.admin@gmail.com" className="google-plus" aria-label="Почта">molbiol.admin@gmail.com</a></p>
                  </div>
                    <Suspense fallback={<div>Loading...</div>}>
                     <LazyGoogleMap />
                    </Suspense>
                </div>
              </div>

              <div className="col-lg-7 mt-5 mt-lg-0 d-flex align-items-stretch">
                <form action="#" className="php-email-form">
                  <div className="row">
                    <div className="form-group col-md-6">
                      <label for="fname">Ваше имя</label>
                      <input
                        type="text"
                        name="fname"
                        className="form-control"
                        id="fname"
                        value={this.state.fname}
                        onChange={(e) =>
                          this.setState({ fname: e.target.value })
                        }
                        required
                      />
                    </div>
                    <div className="form-group col-md-6">
                      <label for="femail">Ваш емейл</label>
                      <input
                        type="email"
                        className="form-control"
                        name="femail"
                        id="femail"
                        value={this.state.femail}
                        onChange={(e) =>
                          this.setState({ femail: e.target.value })
                        }
                        required
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label for="fsubject">Тема (необязательно)</label>
                    <input
                      type="text"
                      className="form-control"
                      name="fsubject"
                      id="fsubject"
                      value={this.state.fsubject}
                      onChange={(e) =>
                        this.setState({ fsubject: e.target.value })
                      }
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label for="fmessage">Сообщение</label>
                    <textarea
                      className="form-control"
                      name="fmessage"
                      id="fmessage"
                      rows="10"
                      onChange={(e) =>
                        this.setState({ fmessage: e.target.value })
                      }
                      required
                    >
                      {this.state.fmessage}
                    </textarea>
                  </div>
                  <div className="my-3">
                    <div className="loading">Loading</div>
                    <div className="error-message"></div>
                    <div className="sent-message">
                      Сообщение успешно отправлено!
                    </div>
                  </div>

                  <div className="my-12">
                    <ReactCaptcha
                      charset="N" //ulns
                      length={6}
                      color="#0082e6"
                      bgColor="#F5F8FD"
                      reload={true}
                      reloadIcon={reloadIcon}
                      reloadText=""
                      handleSuccess={this.handleSuccess}
                      handleFailure={this.handleFailure}
                    />
                  </div>
                  <div className="clear mb-30"></div>

                  <div className="text-center">
                    <button
                      type="submit"
                      className="send_button"
                      onClick={(e) => this.handleFormSubmit(e)}
                    >
                      Отправить
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

import React, { Component} from "react";
import axios from "axios";
import config from "../config";
import Lightbox from "react-18-image-lightbox";
import "react-18-image-lightbox/style.css";

import Isotope from "isotope-layout";

import Button from "./button/Button";
import Modal from "./modal/Modal";
import CCarousel from "./carousel/Carousel";

import '../css/portfolio.css'



import defaultImg from "../img/wait.webp"


export default class Parent extends Component {
  constructor(props) {
    super(props);
    this.onFilterChange = this.onFilterChange.bind(this);
    this.state = {
      data: null,
      photoIndex: 0,
      isOpen: false,
      show:false,
      theposition:0,
      isPageLoaded:false,
      isOpenId:[],
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.listenToScroll);
    axios
      .get(config.ROOT_URL + "/tools/rest/portfolio")
      .then((response) => {
        this.setState({ data: response.data });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.listenToScroll)
  }
  
  listenToScroll = () => {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop
  
    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight
  
    const scrolled = winScroll / height
  
    this.setState({
      theposition: scrolled,
    });

    if(this.state.isPageLoaded === false && scrolled>0.4)
    {

        const lazyImages = document.querySelectorAll(".ak-lazy");
        lazyImages.forEach(function (el) {
            el.src = el.getAttribute('data-src');
            el.removeAttribute('width');
            el.removeAttribute('height');
         });
    

        this.setState({
            isPageLoaded: true,
          });
    }

    
  }



  // при вызове попапа перестроим контентэ
  setShow = (item,val) => {

    if(item !== null)
    {
      document.querySelector('.info-card-title').innerHTML = item.name;
      document.querySelector('.content').innerHTML = item.content;

      if(item.tech !== '')
      {
        document.querySelector('.tech').innerHTML = '<b>Задача</b>: '+item.tech;
      }      
      else
      {
        document.querySelector('.tech').innerHTML = '';
      }   

      if(item.task !== '')
      {
        document.querySelector('.task').innerHTML = '<b>Технологии</b>: '+item.task;
      }
      else
      {
        document.querySelector('.task').innerHTML = '';
      }

      if(item.work !== '')
      {
        document.querySelector('.work').innerHTML = '<b>Выполнено</b>: '+item.work;
      }
      else
      {
        document.querySelector('.work').innerHTML = '';
      }


      let s = [];
      item.images.map((item, index) => (
        
          s.push(config.ROOT_URL + item.source)
        
        
      ))
      this.setState({isOpenId:s});
    }

    this.setState({
      show: val,
    });

  };

  // Click Function
  onFilterChange = (newFilter) => {
    let targetId = newFilter.replace("filter-", "f_");
    if (targetId === "*") {
      targetId = "f_all";
    }
    const portfolioFilters = document.querySelectorAll(".filterlink");
    portfolioFilters.forEach(function (el) {
      el.classList.remove("filter-active");
    });

    document.querySelector("#" + targetId).classList.add("filter-active");

    if (this.iso === undefined) {
      this.iso = new Isotope("#filter-container", {
        itemSelector: ".filter-item",
        layoutMode: "fitRows",
      });
    }

    if (newFilter === "*") {
      this.iso.arrange({ filter: "*" });
    } else {
      this.iso.arrange({ filter: `.${newFilter}` });
    }
  };

  

  render() {
    const { data, photoIndex, isOpen,isOpenId, show } = this.state;

    if (!data) {
      return (
        <section id="portfolio" className="portfolio section-bg">
          <div className="container">
            <div className="section-title">
              <h2>Loading...</h2>
            </div>
          </div>          
        </section>
      )
     
    }


    return (
      <>

      <Modal show={show} onClose={() => this.setShow(null,false)}>
        <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <br />
          </div>
        </div>          
        <div className="row">
          <div className="col-12">
            <div className="info-card-title"></div>

            <CCarousel id={isOpenId}></CCarousel>

            <div className="info-card">
              <div className="task"></div>
              <div className="tech"></div>
              <div className="work"></div>
            </div>

            <div className="content info-card-content">

            </div>
          </div>
        </div>
        </div>
      </Modal>

        <section id="portfolio" className="portfolio section-bg">
          <div className="container">
            <div className="section-title">
              <h2>Портфолио</h2>
              <p>Некоторые из моих работ. Далеко не все...</p>
            </div>
            <div className="row" data-aos="fade-up">
              <div className="col-lg-12 d-flex justify-content-center">
                <ul id="portfolio-flters">
                  <li
                    data-filter="*"
                    className="filterlink filter-active"
                    onClick={(event) => {
                      this.onFilterChange("*");
                    }}
                    id={"f_all"}
                  >
                    Все
                  </li>
                  {data.group.map((item, index) => (
                    <li
                      data-filter={`filter-${item.code}`}
                      key={item.code}
                      onClick={(event) => {
                        this.onFilterChange("filter-" + item.code);
                      }}
                      className="filterlink"
                      id={"f_" + item.code}
                    >
                      {item.name}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div
              id="filter-container"
              className="row portfolio-container"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              {data.data.map((item, index) => (
                <div
                  className={`col-lg-3 col-md-4 portfolio-item filter-item ${item.group}`}
                  key={item.id}
                >
                  <div className="portfolio-wrap">



                  <img className="img-fluid ak-lazy" alt={item.name} src={defaultImg} data-src={config.ROOT_URL + item.images[0].medium} width="306" height="236"/>

                    <div className="portfolio-links">
                      <Button className="btn-sky" title={`Подробнее: ${item.name}`} onClick={() => this.setShow(item,true)}><i className="icomoon icon-news "></i></Button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            {isOpen && (
              <Lightbox
                mainSrc={
                  config.ROOT_URL + data.data[photoIndex].images[0].source
                }
                onCloseRequest={() => this.setState({ isOpen: false })}
              />
            )}
            {isOpen && (
              <div className="lightbox-caption">
                <p>{data.data[photoIndex].name}</p>
              </div>
            )}
          </div>
        </section>
      </>
    );
  }
}

import React, { Component } from 'react'
import reactImg from '../img/logo192.png';
import yii2Img from '../img/yii_logo_dark.png';
import '../css/footer.css'

const year = new Date().getFullYear();

export default class CustomComponent extends Component {
  render() {
    return (
        <>

        <section id="footer" className="footer section-bg">
          <div className="container">
            <div className='text-center'>
            © Andrew_K 2016 - {year}
              <hr />
            <img src={yii2Img} className='footer-icon' alt="Yii2 logo" height="20" width="97" /> <b>+</b>
            <img src={reactImg} className='footer-icon' alt="react logo" height="20" width="20" />
            </div>
          </div>          
        </section>

 
        </>
    )
}
}
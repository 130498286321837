  const devConfig = {
    ROOT_URL: 'https://it-human.ru',
  };
  
  const prodConfig = {
    ROOT_URL: '',
  };
  
  const config = process.env.NODE_ENV === 'production' ? prodConfig : devConfig;

  export default config;

import React, { Component } from "react";
import Carousel from "react-bootstrap/Carousel";

import Lightbox from "react-18-image-lightbox";
import "react-18-image-lightbox/style.css";

import "./style.css";



export default class Parent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: null,
            photoIndex: 0,
            isOpen: false,
            showImg: "",
            index:0,
            setIndex:0,
        };
        this.carouselRef = React.createRef();
    }

    handleClick = (event, index) => {
        event.preventDefault();
        this.setState({
            isOpen: true,
            showImg: event.target.src,
        });
    };






  render() {
    const { id } = this.props;
    const { isOpen, showImg } = this.state;
    const m = new Date().getMinutes();
    const s = new Date().getSeconds();

/*
    const arImg = [];
    const counter = 0;
    id.map((item, index) => (
        //if(index>0)
        //{
            arImg.push(item);
            counter++;
           // console.log(index);
        //}
    ));
*/

    return (
      <>
        <Carousel ref={this.carouselRef} key={m+''+s}>
          {id.map((item, index) => (
            <Carousel.Item key={index}>
              <div className="slide">
                <a
                  href={item}
                  onClick={(event) => this.handleClick(event, index)}
                >
                  <img className=" img-fluid h-100" src={item} alt=""/>
                </a>
              </div>
            </Carousel.Item>
          ))}
        </Carousel>

        {isOpen && (
          <Lightbox
            mainSrc={showImg}
            onCloseRequest={() => {this.setState({ isOpen: false }); } }
          />
        )}
      </>
    );
  }
}

import React, { Component } from "react";
import axios from "axios";
import config from "../config";
import Accordion from 'react-bootstrap/Accordion';

export default class CustomComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
    };
  }

  componentDidMount() {
    axios
      .get(config.ROOT_URL + "/tools/rest/faq")
      .then((response) => {
        this.setState({ data: response.data });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  render() {
    const { data } = this.state;
    const renderHTML = (rawHTML: string) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });

    if (!data) {
      return (
        <section id="faq" className="facts">
          <div className="container">
            <div className="section-title">
              <h2>Loading...</h2>
            </div>
          </div>          
        </section>
      )
     
    }    
    return (
      <div>
        <section id="faq" className="facts">
          <div className="container">
            <div className="section-title">
              <h2>FAQ</h2>

              <Accordion defaultActiveKey="0" flush>
                {data.data.map((item, index) => (
                    <div
                    className="testimonial-item aos-init aos-animate"
                    data-aos="fade-up"
                    data-aos-delay="200"
                    id={`faq${item.id}`}
                    >                    
                        <Accordion.Item eventKey={index}>
                            <Accordion.Header><i className="iconmoon icon-quote-left blue"></i> {renderHTML(item.question)} <i className="iconmoon icon-quote-right blue"></i></Accordion.Header>
                            <Accordion.Body><p>{renderHTML(item.answer)}</p></Accordion.Body>
                        </Accordion.Item> 
                    </div>                  
                ))}
              </Accordion>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

import React from 'react'

export default function Button() 
{
  const handleClick = (e) => {
    document.querySelector('body').classList.toggle('mobile-nav-active');
    e.target.classList.toggle('icon-menu');
    e.target.classList.toggle('icon-close');
  }

  return (
    <i className="icomoon icon-menu mobile-nav-toggle d-xl-none" onClick={handleClick}></i>
  );
}
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { PageOne, PageTwo } from './pages/Pages';
import MainPage from './pages/MainPage';



const App = () => {
  return (
    <BrowserRouter>
      <Routes>
          <Route path="/" element={<MainPage/> } />
          <Route path="*" element={<PageOne />} />
          <Route path="two" element={<PageTwo />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;


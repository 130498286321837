import React, { Component } from 'react'

import bxImg1 from '../img/bx/01.png';
import bxImg2 from '../img/bx/02.png';
import bxImg3 from '../img/bx/03.png';
import bxImg4 from '../img/bx/04.png';
import bxImg5 from '../img/bx/05.png';
import bxImg6 from '../img/bx/06.png';

import bxImg7 from '../img/bx/07.png';
import bxImg8 from '../img/bx/08.png';
import bxImg9 from '../img/bx/09.gif';
import bxImg10 from '../img/bx/10.gif';
import bxImg12 from '../img/bx/12.gif';

import bxImg13 from '../img/bx/13.png';
import bxImg14 from '../img/bx/14.png';
import bxImg15 from '../img/bx/15.png';
import bxImg16 from '../img/bx/16.png';
import bxImg17 from '../img/bx/17.gif';
import bxImg18 from '../img/bx/18.gif';

import bxCertWebp from '../img/bx/cert.webp';
import defaultImg from "../img/wait.webp"

const url = 'https://dev.1c-bitrix.ru/learning/resume.php?ID=41756762-23461';

export default class CustomComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
          theposition2:0,
          isPageLoaded2:false,
        };
      }

      listenToScroll = () => {
        const winScroll = document.body.scrollTop || document.documentElement.scrollTop
        const height = document.documentElement.scrollHeight - document.documentElement.clientHeight
        const scrolled = winScroll / height
      
        this.setState({
          theposition2: scrolled,
        });
    
        if(this.state.isPageLoaded2 === false && scrolled>0.5)
        {
    
            const lazyImages = document.querySelectorAll(".ak-lazy-cert");
            lazyImages.forEach(function (el) {
                el.src = el.getAttribute('data-src');
                el.removeAttribute('width');
                el.removeAttribute('height');
             });
        
    
            this.setState({
                isPageLoaded2: true,
              });
        }
    
        
      }

    componentDidMount() {
        window.addEventListener('scroll', this.listenToScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.listenToScroll)
    }


  render() {
    return (
        <div>
    <section id="cert" className="skills  section-bg">
      <div className="container">

        <div className="section-title">
          <h2>Сертификация Bitrix</h2>
        </div>


        <div className="row">

          <div className="col-lg-6" data-aos="fade-up">
            <p>Очная</p>
            <div className='row'>
                <div className='col-12 text-center mb-30'>
                    <a href={url} className='cert-link' target='_blank' rel="noreferrer" aria-label='Link to bitirx site'>
                        <img data-src={bxCertWebp} height={636} width={446} src={defaultImg} className='img-fluid ak-lazy-cert' alt="cert" />
                    </a>
                </div>
            </div>
          </div>
          <div className="col-lg-6" data-aos="fade-up">
            <p>Онлайн</p>

            <div className='row'>
                <div className='col-6 col-md-4 text-center mb-30'>
                    <a href={url} className='cert-link' target='_blank' rel="noreferrer" aria-label='Link to bitirx site'><img src={defaultImg} height={63} width={168} data-src={bxImg1} className='img-fluid ak-lazy-cert' alt="cert" /></a>
                </div>
                <div className='col-6 col-md-4 text-center mb-30'>
                    <a href={url} className='cert-link' target='_blank' rel="noreferrer" aria-label='Link to bitirx site'><img src={defaultImg} height={63} width={168} data-src={bxImg2} className='img-fluid ak-lazy-cert' alt="cert" /></a>
                </div>
                <div className='col-6 col-md-4 text-center mb-30'>
                    <a href={url} className='cert-link' target='_blank' rel="noreferrer" aria-label='Link to bitirx site'><img src={defaultImg} height={63} width={168} data-src={bxImg3} className='img-fluid ak-lazy-cert' alt="cert" /></a>
                </div>                
                <div className='col-6 col-md-4 text-center mb-30'>
                    <a href={url} className='cert-link' target='_blank' rel="noreferrer" aria-label='Link to bitirx site'><img src={defaultImg} height={63} width={168} data-src={bxImg4} className='img-fluid ak-lazy-cert' alt="cert" /></a>
                </div>
                <div className='col-6 col-md-4 text-center mb-30'>
                    <a href={url} className='cert-link' target='_blank' rel="noreferrer" aria-label='Link to bitirx site'><img src={defaultImg} height={63} width={168} data-src={bxImg5} className='img-fluid ak-lazy-cert' alt="cert" /></a>
                </div>
                <div className='col-6 col-md-4 text-center mb-30'>
                    <a href={url} className='cert-link' target='_blank' rel="noreferrer"aria-label='Link to bitirx site'><img src={defaultImg} height={63} width={168} data-src={bxImg6} className='img-fluid ak-lazy-cert' alt="cert" /></a>
                </div>

                <div className='col-6 col-md-4 text-center mb-30'>
                    <a href={url} className='cert-link' target='_blank' rel="noreferrer" aria-label='Link to bitirx site'><img src={defaultImg} height={63} width={168} data-src={bxImg7} className='img-fluid ak-lazy-cert' alt="cert" /></a>
                </div>
                <div className='col-6 col-md-4 text-center mb-30'>
                    <a href={url} className='cert-link' target='_blank' rel="noreferrer" aria-label='Link to bitirx site'><img src={defaultImg} height={63} width={168} data-src={bxImg8} className='img-fluid ak-lazy-cert' alt="cert" /></a>
                </div>
                <div className='col-6 col-md-4 text-center mb-30'>
                    <a href={url} className='cert-link' target='_blank' rel="noreferrer" aria-label='Link to bitirx site'><img src={defaultImg} height={63} width={168} data-src={bxImg9} className='img-fluid ak-lazy-cert' alt="cert" /></a>
                </div>                
                <div className='col-6 col-md-4 text-center mb-30'>
                    <a href={url} className='cert-link' target='_blank' rel="noreferrer" aria-label='Link to bitirx site'><img src={defaultImg} height={63} width={168} data-src={bxImg10} className='img-fluid ak-lazy-cert' alt="cert" /></a>
                </div>
                <div className='col-6 col-md-4 text-center mb-30'>
                    <a href={url} className='cert-link' target='_blank' rel="noreferrer" aria-label='Link to bitirx site'><img src={defaultImg} height={63} width={168} data-src={bxImg12} className='img-fluid ak-lazy-cert' alt="cert" /></a>
                </div>
                <div className='col-6 col-md-4 text-center mb-30'>
                    <a href={url} className='cert-link' target='_blank' rel="noreferrer" aria-label='Link to bitirx site'><img src={defaultImg} height={63} width={168} data-src={bxImg13} className='img-fluid ak-lazy-cert' alt="cert" /></a>
                </div>

                <div className='col-6 col-md-4 text-center mb-30'>
                    <a href={url} className='cert-link' target='_blank' rel="noreferrer" aria-label='Link to bitirx site'><img src={defaultImg} height={63} width={168} data-src={bxImg14} className='img-fluid ak-lazy-cert' alt="cert" /></a>
                </div>
                <div className='col-6 col-md-4 text-center mb-30'>
                    <a href={url} className='cert-link' target='_blank' rel="noreferrer" aria-label='Link to bitirx site'><img src={defaultImg} height={63} width={168} data-src={bxImg15} className='img-fluid ak-lazy-cert' alt="cert" /></a>
                </div>
                <div className='col-6 col-md-4 text-center mb-30'>
                    <a href={url} className='cert-link' target='_blank' rel="noreferrer" aria-label='Link to bitirx site'><img src={defaultImg} height={63} width={168} data-src={bxImg16} className='img-fluid ak-lazy-cert' alt="cert" /></a>
                </div>
                <div className='col-6 col-md-4 text-center mb-30'>
                    <a href={url} className='cert-link' target='_blank' rel="noreferrer" aria-label='Link to bitirx site'><img src={defaultImg} height={63} width={168} data-src={bxImg17} className='img-fluid ak-lazy-cert' alt="cert" /></a>
                </div>                                                                
                <div className='col-6 col-md-4 text-center mb-30'>
                    <a href={url} className='cert-link' target='_blank' rel="noreferrer" aria-label='Link to bitirx site'><img src={bxCertWebp} height={63} width={168} data-src={bxImg18} className='img-fluid ak-lazy-cert' alt="cert" /></a>
                </div>                
            </div>

            

          </div>
        </div>



      </div>
    </section>
        </div>
    )
}
}
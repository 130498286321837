import React, { Component } from 'react'
import '../fonts/icomoon/style.css'
import axios from 'axios';
import profileImg from '../img/me_deal.png';
import profileImgWebp from '../img/me_deal.webp';
import config from '../config';


export default class Header extends Component {

    constructor(props) {
        super(props);
        this.state = {
          data: null,
        };
      }

    componentDidMount() {
        window.addEventListener('scroll', this.listenToScroll);
        axios
          .get(config.ROOT_URL + "/tools/rest/is_admin")
          .then((response) => {
            this.setState({ data: response.data.data.res });
            console.log(response.data.data.res);
          })
          .catch((error) => {
            console.error(error);
          });
      }

    handleClick = (e) => {//
        if( document.querySelector('.mobile-nav-toggle').classList.contains('icon-close') )
        {
            document.querySelector('.mobile-nav-toggle').click();
        }
        
    }

  render() {
    const {data} = this.state;
    const renderHTML = (rawHTML) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });

      let url = '';
      if(data==='Y')
      {
        url = '<li><a href="/tools/admin/">Админ-панель</a></li>';
      }

    return (
        <div>
        <header id="header">
        <div className="d-flex flex-column">

        <div className="profile">
            <picture >
                <source srcset={profileImgWebp} type="image/webp" alt="Фото" className="img-fluid rounded-circle" width="120" height="120" />
                <img src={profileImg} alt="Фото" className="img-fluid rounded-circle"  width="120" height="120" />
            </picture>

            <h1 className="text-light">
                Andrew_K
            </h1>
            <div className="social-links mt-3 text-center">
            <a target="_blank" href="https://t.me/andrew_k_ru" className="telegram" aria-label="Телеграм"><i className="icomoon icon-telegram"></i></a>
            <a target="_blank" href="skype:andrew-k-ru?call" className="google-plus" aria-label="Скайп"><i className="icomoon icon-skype"></i></a>
            <a target="_blank" href="mailto:molbiol.admin@gmail.com" className="google-plus" aria-label="Почта"><i className="icomoon icon-envelope"></i></a>
            <a target="_blank" href={config.ROOT_URL+'/tools/pdf'} className="google-plus" aria-label="Резюме"><i className="icomoon icon-printer"></i></a>
            </div>
            
        </div>

        <nav id="navbar" className="nav-menu navbar">
            <ul>
            <li><a href="#hero" aria-label="Домой" className="nav-link scrollto " onClick={(event) => this.handleClick(event)}><i className="icomoon icon-home"></i><span>Домой</span></a></li>
            <li><a href="#resume" aria-label="Резюме" className="nav-link scrollto" onClick={(event) => this.handleClick(event)}><i className="icomoon icon-file-blank"></i> <span>Резюме</span></a></li>
            <li><a href="#portfolio" aria-label="Портфолио" className="nav-link scrollto" onClick={(event) => this.handleClick(event)}><i className="icomoon icon-sidebar"></i> <span>Портфолио</span></a></li>
            <li><a href="#faq" aria-label="FAQ" className="nav-link scrollto" onClick={(event) => this.handleClick(event)}><i className="icomoon icon-server"></i> <span>FAQ</span></a></li>
            <li><a href="#contact" aria-label="Контакты" className="nav-link scrollto" onClick={(event) => this.handleClick(event)}><i className="icomoon icon-envelope"></i> <span>Контакты</span></a></li>
            {renderHTML(url)}
            </ul>
        </nav>
        </div>
        </header>
        </div>
    )
}
}


import React, { Component } from 'react';
import { Waypoint } from 'react-waypoint';
import '../css/skills.css';

const skills = [
  {'name':'PHP','value':'65'},
  {'name':'Python','value':'35'},
  {'name':'Perl','value':'20'},
  {'name':'Javascript','value':'55'},
  {'name':'jQuery','value':'60'},
  {'name':'HTML5','value':'55'},
  {'name':'CSS3','value':'50'},
  {'name':'Photoshop','value':'35'},
  {'name':'Alligator Flash Designer','value':'35'},
  {'name':'Borland Delphi','value':'40'},
  {'name':'Free Pascal','value':'25'},
  {'name':'Qt C++','value':'23'},
  {'name':'MySQL','value':'45'},
  {'name':'Conf apache,nginx,mysql etc','value':'35'},
  {'name':'SEO','value':'20'},
];


export default class CustomComponent extends Component {

  render() {
    return (
        <Waypoint  
        onEnter={function(props) {

          let progress = document.querySelectorAll('.progress .progress-bar');

          setTimeout(() => {
            progress.forEach((el) => {
              el.style.width = el.getAttribute('aria-valuenow') + '%'
            });
          }, 1000);
        }}
     
        >
        <section id="skills" className="skills">
          <div className="container">

            <div className="section-title">
              <h2>Навыки</h2>
            </div>

            <div className="row skills-content">

              
              {skills.map((item, index) => (
                <div className="col-lg-6" data-aos="fade-up">

                <div className="progress">
                  <span className="skill">{item.name} <i className="val">{item.value}%</i></span>
                  <div className="progress-bar-wrap">
                    <div className="progress-bar" role="progressbar" aria-label={item.name} aria-valuenow={item.value} aria-valuemin="0" aria-valuemax="100"></div>
                  </div>
                </div>
                </div>

              ))}






            </div>

            <p>* Методика оценки, естественно, исключительно субъективная. (Подробнее в разделе FAQ). Но, в общем виде, если скилл упомянут в этом разделе - что-то в этой области я все-таки могу и умею.</p>

          </div>
        </section>
        </Waypoint>
    )
}
}
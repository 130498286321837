import React, { Component } from 'react'
import axios from "axios";
import config from "../config";
import '../css/resume.css'

export default class CustomComponent extends Component {
  constructor(props) {
    super(props);
      this.state = {
      data: null,
    };
  }


  componentDidMount() {
    axios
      .get(config.ROOT_URL + "/tools/rest/resume")
      .then((response) => {
        this.setState({ data: response.data });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  render() {
    const {data} = this.state;
    const renderHTML = (rawHTML) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });

    if (!data) {
      return (
        <section id="portfolio" className="portfolio section-bg">
          <div className="container">
            <div className="section-title">
              <h2>Loading...</h2>
            </div>
          </div>          
        </section>
      )
     
    }

    return (
        <div>
     <section id="resume" className="resume">
      <div className="container">

        <div className="section-title">
          <h2>Резюме</h2>
        </div>

        <div className="row">
          <div className="col-lg-12" data-aos="fade-up">
            {data.data.map((item, index) => (
              <>
                <h3 className="resume-title">{item.name}</h3>
                <div className="resume-item">
                  {( item.subname.length <= 0 ? ('') : (<h4>{item.subname}</h4>) )}
                  {( item.timeline.length <= 0 ? ('') : (<h5>{item.timeline}</h5>) )}
                  {( item.company.length <= 0 ? ('') : (<p><em>{item.company}</em></p>) )}
                  <div>{renderHTML(item.content)}</div>
                  <div><small>{renderHTML(item.additional)}</small></div>
                </div>
              </>
            ))}
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12 text-center mt-30 mb-20" data-aos="fade-up">
          <a className="btn btn-success get-pdf" title={`Скачать резюме в PDF`} target="_blank" href='/tools/pdf'><i className="icomoon icon-file-pdf "></i> Скачать резюме</a>
          </div>
        </div>


      </div>
    </section>
        </div>
    )
}
}
import React, { Component } from 'react'

class BackgroundImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentImageIndex: 0,
      images: [
        "01.webp",
        "02.webp",
        "03.webp",
        "04.webp",
        "05.webp",
        "06.webp",
        "07.webp",

      ],
    };
  }

  componentDidMount() {
    this.interval = setInterval(this.changeImage, 7000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  changeImage = () => {
    const { currentImageIndex, images } = this.state;
    const nextImageIndex = (currentImageIndex + 1) % images.length;
    this.setState({ currentImageIndex: nextImageIndex });
  }

  render() {
    const { currentImageIndex, images } = this.state;
    const imageUrl = require(`../img/${images[currentImageIndex]}`);
    const style = {
      backgroundImage: `url(${imageUrl})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',      
    };
    return (
        <div id="hero-inner" className="background-image" style={style}>
        <section id="hero" className="d-flex flex-column justify-content-center align-items-center">
                <div className="hero-container text-center" data-aos="fade-in">
                <p>Здравствуйте, я</p>                  
                <h1>Andrew_K</h1>
                <p>программист</p>
                <p className='text-center'>
                <a href='#resume' className='orange-big-link' aria-label='Перейти к резюме'><i className="iconmoon icon-up-arrow orange "></i></a>
                </p>
                </div>
        </section>
        </div>    
    
    )
  }
}

export default BackgroundImage;
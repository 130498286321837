import MobileNav from '../components/mobile-nav'
import Header from '../components/header'
import Hero from '../components/hero'
//import About from '../components/about'
//import Facts from '../components/facts'
import Faq from '../components/faq'
import Skills from '../components/skills'
import Cert from '../components/cert'
import Resume from '../components/resume'
import Portfolio from '../components/portfolio'
//import Services from '../components/services'
//import Testimonials from '../components/testimonials'
import Contact from '../components/contact'
import Footer from '../components/footer'

const MainPage = () => (
  <div>
    <MobileNav></MobileNav>
    <Header></Header>
    <Hero></Hero>
    <main id="main">
      <Resume></Resume>
      <Portfolio></Portfolio>
      <Skills></Skills>
      <Cert></Cert>
      <Faq></Faq>
      <Contact></Contact>
      <Footer></Footer>
    </main>
  </div>
);
export default MainPage;
